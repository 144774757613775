import Utils from '@/utils/Utils';
import UtilsRequest from '@/utils/UtilsRequest';

const API_URL = 'Notifiche/';

class NotificheService {
    getNotificheUtente(userId, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("userId", userId);

        UtilsRequest.get(API_URL + "GetNotificheUtente", parametri,
        successFn,
        failFn
        );
    }

    getTemplateNotificaVeicolo(idVeicolo, idUser, successFn, failFn){
        const parametri = new URLSearchParams();
        parametri.append("idVeicolo", idVeicolo);
        parametri.append("idUser", idUser);

        UtilsRequest.get(API_URL + "GetTemplateNotificaVeicolo", parametri,
        successFn,
        failFn
        );
    }

    chiudiNotifica(notifica, successFn, failFn){
        UtilsRequest.post(API_URL + "ChiudiNotifica", notifica, 
        successFn,
        failFn
        );
    }

    chiudiNotifiche(notifiche, successFn, failFn){
        UtilsRequest.post(API_URL + "ChiudiNotifiche", notifiche, 
        successFn,
        failFn
        );
    }

    createNotificaVeicolo(notifica, successFn, failFn){
        UtilsRequest.post(API_URL + "CreateNotificaVeicolo", notifica, 
        successFn,
        failFn
        );
    }
    
    riapriNotifica(notifica, successFn, failFn){
        UtilsRequest.post(API_URL + "RiapriNotifica", notifica, 
        successFn,
        failFn
        );
    }
}

export default new NotificheService();