<template>
  <v-form ref="form" class="mx-2" method="post" enctype="multipart/form-data">
  <v-container id="NOTIFICHE" fluid tag="section">
    <v-row>
      <v-col :cols="isEditNotifica ? '4' : '12'">
        <base-material-card
          color="white"
          iconcolor="darkred"
          icon="mdi-bell"
          :title="$t('page.notifiche.titolo')"
          class="px-5 py-3"
        >
          <v-container v-if="isCreatedCorrectly" v-resize="onContainerChange">
            <v-row>
              <v-col>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('notifiche.ricerca')"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
              </v-col>
              <v-col style="margin-top: 7px">
                <v-select 
                  :items="t_statonotifica"
                  v-model="statoSel"
                  :label="$t('stato')"
                  @change="onStatoChange"
                  >
                </v-select>
              </v-col>
              <v-col style="margin-top: 7px">
                <!--
                <v-text-field
                  :label="$t('dataDa')"
                  type="date"
                  min="1990-01-01"
                  max="2050-12-31"
                >
                </v-text-field>
                -->
              </v-col>
              <v-col style="margin-top: 7px">
                <!--
                <v-text-field
                  :label="$t('dataA')"
                  type="date"
                  min="1990-01-01"
                  max="2050-12-31"
                >
                </v-text-field>
                -->
              </v-col>
              <v-col></v-col>
              <v-col align="end" style="margin-top: 20px">
                <v-btn 
                  color="darkred" 
                  width="150px"
                  @click="onRisolviClick">
                  <v-icon left> mdi-solar-panel </v-icon>
                  {{ $t("risolvi") }}
                </v-btn>
              </v-col>
              <v-col align="end" style="margin-top: 20px">
                <v-btn 
                  color="darkred" 
                  width="150px"
                  @click="onRisolviTuttoClick">
                  <v-icon left> mdi-solar-panel-large </v-icon>
                  {{ $t("risolviTutto") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-data-table
            style="max-height:600px; overflow:auto"
            id="tblMainNotifiche"
            :items="notifiche.source"
            :headers="headersColumns"
            :search="search"
            item-key="id"
            :value="[notifiche.selected]"
            :items-per-page="-1"
            @click:row="onTableRowClick"
            single-select
            class="elevation-1"
            :loading="isloading_notifiche"
            loading-text="Caricamento dati in corso..."
          >
            <template v-slot:[`item.isNotificaSel`]="{ item }">
              <v-checkbox v-show="item.shouldCheckDisabled"
                v-model="item.isNotificaSel"
                dense
                :ripple="false"
                @change="onNotificaClick(item, $event)"
              ></v-checkbox>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>

      <v-col v-if="isEditNotifica" cols="8">
        <base-material-card
          color="white"
          iconcolor="darkred"
          icon="mdi-toolbox"
          :title="
            $t('notifiche.notifica') + ': ' + notifiche.selected.tipoNotifica.cDescrizione
          "
          class="px-5 py-3"
        >
          <v-row style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px" >
            <v-col>
              <v-text-field
                v-model="notifiche.selected.tipoNotifica.cDescrizione"
                :label="$t('notifiche.tipoNotifica')"
                readonly
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="notifiche.selected.cOggettoDef"
                :label="$t('notifiche.tipoOggetto')"
                readonly
              >
              </v-text-field>              
            </v-col>
            <v-col>
              <v-text-field
                v-model="notifiche.selected.dataNotificaText"
                :label="$t('notifiche.dataNotifica')"
                readonly
              >
              </v-text-field>              
            </v-col>
          </v-row>
          <v-row style="margin-left: 20px; margin-bottom: 10px" >
            <v-col>
              <v-text-field
                v-model="notifiche.selected.xDescrizione"
                :label="$t('notifiche.descrizione')"
                readonly
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="notifiche.selected.tipoOggetto"
                :label="$t('notifiche.origine')"
                readonly
              >
              </v-text-field>              
            </v-col>
            <v-col>
              <v-text-field
                v-model="notifiche.selected.stato"
                :label="$t('notifiche.stato')"
                readonly
              >
              </v-text-field>              
            </v-col>
          </v-row>
          <v-row style="margin-left: 20px; margin-bottom: 10px" >
            <v-col cols="4">
              <v-text-field
                v-model="notifiche.selected.cOggetto"
                :label="$t('notifiche.oggettoMail')"
                readonly
              >
              </v-text-field>
            </v-col>
            <v-col cols="8">
              <v-textarea
                v-model="notifiche.selected.xTesto"
                outlined
                :label="$t('notifiche.testoMail')"
                readonly
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-col>
              </v-col>
              <v-col>
              </v-col>
            </v-col>
            <v-col>
              <v-col>
              </v-col>
            </v-col>

            <v-col>
            </v-col>
          </v-row>
          

          <v-divider class="mx-4"></v-divider>

          <v-row style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px" >
            <v-btn
              v-show="notifiche.selected.stato == 'Chiuso'"
              tile
              color="darkred"
              @click="onRipristinaClick"
            >
              <v-icon left> mdi-content-duplicate </v-icon>
              {{ $t("notifiche.ripristina") }}
            </v-btn>
            
            <v-btn tile color="darkred" @click="closeDetail">
              <v-icon left> mdi-door-open </v-icon>
              {{ $t("chiudi") }}
            </v-btn>
            
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-alert
        id="alertGrid"
        shaped
        :value="isAlertGridVisible"
        :type="alertType"
        dismissible
      >
        {{ messaggioAlert }}
      </v-alert>
    </v-row>      
  </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import i18n from "@/i18n";

import Utils from "@/utils/Utils";
import UtilsDate from "@/utils/UtilsDate";

import NotificheService from "@/service/NotificheService";
import ExcelService from "@/service/ExcelService";

@Component
export default class Notifiche extends Vue {
  notifiche = {
    selected: null,
    source: [],
  };

  t_notifiche = [
    "Tutte",
    "Scadenza",
    "Manutenzione",
    "Buono",
    "Rifornimento",
    "Fornitore",
    "Utenti",
  ];

  t_statonotifica = ["Tutti", "Aperto", "Chiuso"];

  headersColumns = [
    {
      text: "Tipologia",
      align: "start",
      value: "tipoNotifica.cDescrizione",
    },
    { text: "Oggetto Notifica", value: "cOggettoDef" },
    { text: "Data", value: "dataNotificaText" },
    { text: "Descrizione", value: "xDescrizione" },
    { text: "Origine", value: "tipoOggetto", filter: this.tipoNotificheFilter },
    { text: "Stato", value: "stato", filter: this.statoFilter },
    { text: "", value: "isNotificaSel", sortable: false },
  ];

  rules = Utils.rules;

  // Variabili
  dimension = 0;
  search = "";
  alertType = "error";
  messaggioAlert = "";
  statoSel = "Aperto";
  tipoNotSel = "Tutte";

  isEditNotifica = false;
  isNotificaSel = false;
  isCreatedCorrectly = false;
  isAlertGridVisible = false;
  isloading_notifiche = false;
  shouldCheckDisabled = false;
  
  created() {
    let verLoadingPage = Utils.verifyLoadingPage(this.$store, "R_NOTIFICHE");
    switch (verLoadingPage)
    {
      case 0: // All OK
        this.isCreatedCorrectly = true
        this.loadNotifiche();
        break;
      
      case 1: // Utente non loggato
        AuthService.logout();
        break;

      case 2: // Ente non selezionato
        this.$router.push({ name: "Dashboard" });
        break;

      case 3: // Permesso alla pagina non presente
        this.$router.push({ name: "Dashboard" });
    }

  }

  onTableRowClick(notifica, row) {
    this.notifiche.selected = notifica;

    console.log("Notifica selezionata: ");
    console.log(this.notifiche.selected);

    this.onContainerChange();

    this.isEditNotifica = true;
  }

  onContainerChange() {
    let self = this;
    // console.log("onContainerChange");
    self.$nextTick(function () {
      self.dimension = document.getElementById("tblMainNotifiche").offsetWidth;
      return self.dimension;
    });
  }

  onNotificaClick(item, event) {
  }
  
  onRipristinaClick() {
    this.ripristinaNotifica();
  }

  onRisolviClick() {
    let notificheDaRisolvere = [];
    // Ciclo tutte le notifiche per passare solo le selezionate
    for (let i = 0; i < this.notifiche.source.length; i++) {
      var item = this.notifiche.source[i];
      if (item.isNotificaSel) {
        notificheDaRisolvere.push(item);
      }
    }
    //
    if (notificheDaRisolvere.length > 0)
      this.chiudiNotifiche(notificheDaRisolvere);
    else
    {
          this.alertType = "error";
          this.messaggioAlert = i18n.t("erroreRisolviNotificaSel");
          this.showHide_alertGrid(5000);      
    }
  }

  onRisolviTuttoClick() {
    let notificheDaRisolvere = [];
    // Ciclo tutte le notifiche per passare solo quelle aperte
    for (let i = 0; i < this.notifiche.source.length; i++) {
      var item = this.notifiche.source[i];
      if (item.stato == "Aperto") {
        notificheDaRisolvere.push(item);
      }
    }
    //
    if (notificheDaRisolvere.length > 0)
      this.chiudiNotifiche(notificheDaRisolvere);
    else
    {
          this.alertType = "error";
          this.messaggioAlert = i18n.t("erroreRisolviNotifiche");
          this.showHide_alertGrid(5000);      
    }
    // Passo tutte le notifiche presenti
    //this.chiudiNotifiche(this.notifiche.source);
  }

  onStatoChange() {
    
  }

  closeDetail() {
    this.isEditNotifica = false;
  }

  statoFilter(value) {
    if (this.statoSel == "Tutti")
      return true;
    if (this.statoSel == value)
      return true;
    else
      return false;
  }

  tipoNotificheFilter(value) {
    if (this.tipoNotSel == "Tutte")
      return true;
    
    if (this.tipoNotSel == value)
      return true;
    else
      return false;
  }

  showHide_alertGrid(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertGridVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertGridVisible = false;
      //console.log("hide alert after " + millisecondInterval / 1000 + " seconds");
    }, millisecondInterval);
  }

  /// **************************
  /// DATABASE
  /// **************************

  loadNotifiche() {
    var self = this;
    var userId = this.$store.state.user.id;
    self.isloading_notifiche = true;
    NotificheService.getNotificheUtente(userId,
      (resp) => {
        console.log("getNotificheUtente");
        console.log(resp);
        
        self.notifiche.source = resp;
        for (let i = 0; i < self.notifiche.source.length; i++) {
          var item = self.notifiche.source[i];
          item.dataNotificaTextIta = UtilsDate.convertStringToDateIta(item.dNotifica);
          item.isNotificaSel = false;
          item.shouldCheckDisabled = item.stato == "Aperto";
        }
        self.isloading_notifiche = false;
      },
      (err) => {
        console.log("Errore getNotificheUtente");
        console.log(err);
        self.isloading_notifiche = false;
        return null;
      }
    );
  }

  chiudiNotifiche(notifiche) {
    var self = this;

    NotificheService.chiudiNotifiche(
      notifiche,
      (resp) => {
        console.log("chiudiNotifiche");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          // Aggiorno la griglia
          self.loadNotifiche();
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
        }

        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = err;
        self.showHide_alertGrid(5000);
      }
    );
  }

  ripristinaNotifica() {
    var self = this;

    NotificheService.riapriNotifica(
      self.notifiche.selected,
      (resp) => {
        console.log("riapriNotifica");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          // Aggiorno la griglia
          self.closeDetail();
          self.loadNotifiche();
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
        }

        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = err;
        self.showHide_alertGrid(5000);
      }
    );
  }

}
</script>

<style>
</style>